<template>
  <div>
    <Title :text="translations.title_exhibitor_list" />
    <div class="viewcontent">
      <SearchList :data="exhibitors" :type="'exhibitor'" />
      <button @click="getAllExhibitors()" v-if="filter.keyword !== '*'">
        {{ translations.btn_showall_exhibitors }}
      </button>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import SearchList from '@/components/SearchList.vue'

  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ExhibitorList',
    components: {
      Title,
      SearchList,
    },
    data() {
      return {
        filter: {},
      }
    },
    computed: {
      ...mapState(['exhibitors', 'translations']),
    },
    methods: {
      ...mapActions(['getExhibitors', 'resetExhibitorList']),
      async getAllExhibitors() {
        this.filter = { keyword: '*' }
        await this.getExhibitors(this.filter)
      },
    },

    async created() {
      console.log(this.exhibitors.list.exhibitors)
      if (!this.exhibitors.list.exhibitors) {
        this.filter = JSON.parse(localStorage.getItem('exhibitor'))
        await this.getExhibitors(this.filter)
      }
    },
    beforeUnmount() {
      this.resetExhibitorList()
    },
  }
</script>

<style scoped></style>
